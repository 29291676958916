<template>
  <div class="row">
    <div class="form-title">
      <h4>Allocated Devices</h4>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Device Name</th>
          <th>Device Location</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="device in deviceNames" :key="device.device_id">
          <td>{{ device.device_name }}</td>
          <td>{{ device.device_loc }}</td>
        </tr>
      </tbody>
    </table>
    <my-table
      v-if="transactions"
      :tableaction="true"
      page_title="Download Reports"
      :headers="headers"
      :items="transactions"
      :AmountSum="AmountSum"
      :AmountSumArray="AmountSumArray"
      :buttons="true"
      :overwrite_default_action="true"
      @reverse="reverseTransaction"
      download_reports_flag="true"
    >
      <template slot="action" slot-scope="{ item, index }">
        <p v-if="item.status && item.status == 'declined'">The Reversal has been declined by the admin. If you have questions please contact to the admin.</p>
        <button v-if="!item.status"
          class="btn btn-primary btnsm"
          @click.prevent="reverseTransaction(item, index)"
        >
          Reverse
        </button>
      </template>
    </my-table>
  </div>
</template>

<script>
import db from "@/db";
import config from "@/config";
export default {
  data() {
    return {
      deviceNames: {},
      headers: [
        { text: "Amount", value: "amount" },
        { text: "Transaction ID", value: "trxId" },
        { text: "Device Name", value: "device_name" },
        { text: "Transaction Date", value: "trxDate" },
        { text: "Transaction Status", value: "trxStatus" },
      ],
      transactions: [],
      AmountSumArray: [],
      AmountSum: 0.0,
    };
  },
  beforeMount() {
    let user = config.getUser();
    // let device_ides=user.deviceIds.map(t=>t.device_id);
    this.deviceNames = user.deviceIds;
    // console.log('1', this.deviceNames)
    db.collection("Transaction")
      /*.where("device_id","in",device_ides)*/
      /*.where("status","in",["reverse","reverse_done",null])*/
      .where("email", "==", user.email)
      .get()
      .then((trasnap) => {
        trasnap.forEach(async (trx) => {
          // if (!Object.prototype.hasOwnProperty.call(trx.data(), "status")) {
            let trxdata = trx.data();
            // console.log(trxdata)
            let devices = await db
              .collection("poses")
              .where("code", "==", trxdata.device_id)
              .get();
              if (trxdata.status !== 'reverse_done') {
                if (devices.docs.length > 0) {
                  trxdata.device_name = devices.docs[0].data().name;
                }
                trxdata.id = trx.id;
                this.AmountSumArray.push(parseFloat(trxdata.amount));
                this.transactions.push(trxdata);
              } 
              // else if (trxdata.status == 'reverse_done') {
              //   console.log('2', trxdata)
              // }
          // }
        });

        // this.AmountSum = this.AmountSumArray.reduce((a, b) => a + b, 0.0);
      });
  },
  methods: {
    reverseTransaction(trx, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reverse it!",
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("Transaction")
            .doc(trx.id)
            .update({ status: "reverse" })
            .then(() => {
              this.$swal.fire(
                "Transaction reverse request has been submitted.",
                "",
                "success"
              );
              this.transactions.splice(index, 1);
            });
        }
      });
    },
  },
};
</script>