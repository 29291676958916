import Vue from 'vue'
import App from './App.vue'
import Vuelidate from "vuelidate";
import VueRouter from "vue-router"
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// // Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// // Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.use(Vuelidate);

Vue.use(VueRouter);


// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
//Bootstrap and jQuery libraries
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import Table from "@/templates/Table";

Vue.use(VueSweetalert2);

Vue.component("my-table",Table)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
