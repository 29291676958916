<template>
  <div class="forms">
    <h2 class="title1">Merchant Profile</h2>
    <div class="form-grids row widget-shadow" data-example-id="basic-forms">
      <div class="form-title">
        <h4>Update Profile</h4>
        <span class="text-danger" v-if="error">{{ error }}</span>
        <span class="text-success" v-if="success">{{ success }}</span>
      </div>
      <div class="form-body">
        <form method="post" @submit.prevent="saveProfile" autocomplete="off">
          <div class="form-group col-md-6">
            <label for="username">Username</label>
            <input
              type="text"
              class="form-control"
              id="username"
              placeholder="User name"
              v-model="user_profile.username"
            />
            <span class="text-danger" v-if="$v.user_profile.username.$error"
              >This field is required</span
            >
          </div>
          <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="User email"
              v-model="user_profile.email"
            />
            <span class="text-danger" v-if="$v.user_profile.email.$error"
              >This field is required</span
            >
          </div>
          <div class="form-group col-md-6">
            <label for="mobile">Mobile</label>
            <input
              type="text"
              class="form-control"
              id="mobile"
              placeholder="User mobile"
              v-model="user_profile.phone"
            />
            <span class="text-danger" v-if="$v.user_profile.phone.$error"
              >This field is required</span
            >
          </div>
          <div
            v-if="user_profile.isForcePasswordChange"
            class="form-group col-md-6"
          >
            <label for="mobile">Update Password</label>
            <input
              type="password"
              class="form-control"
              id="mobile"
              placeholder="User mobile"
              v-model="user_profile.password"
            />
            <span class="text-danger" v-if="$v.user_profile.password.$error"
              >This field is required</span
            >
          </div>
          <div class="form-group col-md-12">
            <button type="submit" class="btn btn-default">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config";
import { required } from "vuelidate/lib/validators";
import db from "@/db";
export default {
  data() {
    return {
      error: "",
      success: "",
      user_profile: {},
    };
  },
  validations: {
    user_profile: {
      username: { required },
      email: { required },
      phone: { required },
      password: { required },
    },
  },
  beforeMount() {
    this.user_profile = config.getUser();
  },
  methods: {
    saveProfile() {
      this.$v.user_profile.$touch();
      if (this.$v.user_profile.$error) return;
      let update_data = {
        username: this.user_profile.username,
        email: this.user_profile.email,
        phone: this.user_profile.phone,
        password: this.user_profile.password,
      };
      db.collection("merchants")
        .doc(this.user_profile.id)
        .update(update_data)
        .then(() => {
          this.success = "Profile Updated Successfully.";
          sessionStorage.setItem("user", JSON.stringify(this.user_profile));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>